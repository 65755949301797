<template>
  <div class="ui fluid container">
    <div class="ui raised segment">
      <Logs :logs="orderLogs" />

      <p v-if="paginator.count">
        Displaying results {{ (paginator.currentPage - 1) * 10 + 1 }}-{{
          (paginator.currentPage - 1) * 10 + 11 > paginator.count
            ? paginator.count
            : (paginator.currentPage - 1) * 10 + 11
        }}
        of {{ paginator.count }}.
      </p>
      <div v-if="paginator.count" class="ui pagination menu">
        <a
          v-for="page in paginator.pages"
          :key="page"
          :class="{ active: page === paginator.currentPage }"
          @click="page !== paginator.currentPage ? changePage(page) : () => {}"
          class="item"
        >
          {{ page }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Logs from "@/components/Logs.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Logs
  },

  data() {
    return {
      paginator: {
        count: 0,
        currentPage: 1,
        pages: null
      },

      loading: {
        orderLogs: true,
      }
    };
  },

  computed: {
    isLoading() {
      return Object.keys(this.loading).reduce(
        (acc, v) => acc || this.loading[v],
        false
      );
    },

    ...mapState(["orderLogs"])
  },

  methods: {
    changePage(page) {
      this.paginator.currentPage = page;
      let query = `?page=${page}`;

      this.loading.orderLogs = true;
      this.loadOrderLogs({ query }).then(data => {
        this.paginator.count = data.count;
        this.paginator.pages = data.pages;
        this.loading.orderLogs = false;
      });
    },

    ...mapActions(["loadOrderLogs"])
  },

  async created() {
    const data = await this.loadOrderLogs();
    this.paginator.count = data.count;
    this.paginator.pages = data.pages;
    this.loading.orderLogs = false;
  }
};
</script>

<style scoped>
.container {
  padding: 0 3em 0 3em;
}
</style>
